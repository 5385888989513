import React, { useState, useContext } from 'react';
import LanguageContext from "../context/LanguageContext";
import faqBanner from '../images/FAQ_banner.webp';

const faqData2 = {
  English: [
    { question: "What materials can be used to produce renewable diesel?", answer: "Renewable diesel is produced from such materials as rendered animal fats, vegetable oils, distillers corn oil, canola oil, and used cooking oils." },
    { question: "Does renewable diesel have other names?", answer: "Yes, renewable diesel is also known as hydrotreated vegetable oil (HVO) or green diesel." },
    { question: "Is renewable diesel the same as biodiesel?", answer: "No, renewable diesel isn’t the same as biodiesel. While both are made from vegetable oils or animal fats -- not petroleum -- they are processed differently, have different performance attributes, and only renewable diesel is “engine-ready” and can fully replace petroleum diesel without engine modifications. Biodiesel must be blended with other fuels. Most manufacturers’ engines are calibrated to operate with no more than 20% of their fuel blend from biodiesel." },
    { question: "Does renewable diesel burn cleaner than petroleum diesel or biodiesel?", answer: "Gallon for gallon, burning renewable diesel produces lower particulate matter, oxides of nitrogen, and aromatic hydrocarbon emissions than petroleum fuels. Renewable diesel also has a higher cetane than petroleum-based diesel or biodiesel. Cetane is similar to the octane rating of regular gasoline, in that higher cetane prevents premature fuel combustion that can cause engine knocking. This higher cetane also reduces engine emissions." },
    { question: "Isn’t distillers corn oil (DCO) related to ethanol? Is renewable diesel the same as ethanol?", answer: "Distillers corn oil is a byproduct of the corn ethanol manufacturing process. It has been used in animal feed. Renewable diesel isn’t the same as ethanol, but renewable diesel can be produced using this ethanol byproduct." },
    { question: "Why is renewable diesel suddenly in high demand?", answer: "Federal and state credit systems require fuel importers, refiners and wholesalers to include an increasing proportion of renewable fuels in the products they sell, to comply with regulations. Renewable diesel generates needed credits in state programs and renewable identification numbers (RINs) in the federal system. Additionally, renewable diesel can be used in conventional engine systems as a complete replacement for or in combination with petroleum diesel without requiring any modifications to conventional engines. Renewable diesel also has a higher cetane than petroleum-based diesel or biodiesel. Cetane is similar to the octane rating of regular gasoline, in that higher cetane prevents premature fuel combustion that can cause engine knocking. This higher cetane also reduces engine emissions." },
    { question: "What is LCFS?", answer: "LCFS is the acronym for California’s Low Carbon Fuel Standard, which is designed with a stated goal of decreasing the carbon intensity of California’s transportation fuels and driving production of a broader range of low-carbon and renewable alternative fuels. LCFS is one of a group of programs enacted, and similar programs are being considered by many states and nations. The LCFS system drives demand for lower-carbon fuels through a system of credits, deficits and required proportions of renewable fuels to conventional fuels. In the LCFS program, fuels generate LCFS credits if they have a carbon intensity (CI) value lower than the year’s program target. Fuels with CI values higher than the year’s program target generate LCFS deficits. Fuel producers with deficits must generate and acquire enough credits to remain in compliance with the standard." },
    { question: "How do provincial and federal fuel standards work?", answer: "Fuel-standard programs have established requirements to blend or use a certain percentage of renewable fuels in the hydrocarbon fuel volumes. These programs are adjusted and overseen by federal and provincial programs to ensure renewable fuels are blended into fuels on an annual basis. These programs are designed to lower carbon emissions from transportation fuels." },
    { question: "What are the clean fuel regulations in Quebec, Ontario and B.C.?", answer: "As of January 1, 2023, Quebec requires 10% low-carbon fuel content in gasoline in 2023 and increase this to 15% by 2030. The Cleaner Transportation Fuels regulation in Ontario requires that fuel suppliers blend 10% of renewable content in gasoline from 2020 to 2024. In British Columbia the Renewable and Low Carbon Fuel Requirement mandates a 5% ethanol content in gasoline and 4% in diesel fuel." },
    { question: "What is carbon intensity?", answer: "Carbon intensity, also known as CI, is a measure of how much carbon dioxide (CO2) or carbon dioxide equivalent (CO2-e) is emitted by the complete lifecycle of a fuel per unit of energy delivered. Carbon intensity varies based on the many lifecycle factors of feedstock production, conversion into fuel, and use. Renewable diesel has a lower CI value than petroleum diesel, but the CI value varies based on the feedstock and the process to transform the material into fuel. For example, petroleum diesel has a CI value of about 100, renewable diesel from soybean oil typically has CI value of 53-58, and renewable diesel from animal fat typically has a CI score of 24-52." },
    { question: "Why does a fuel type have a CI range instead of a set number?", answer: "Fuels have ranges instead of set CI values because the CI value of a specific fuel will vary based on the lifecycle emissions associated with how the feedstock is produced, processed, and transported as well as its emissions impact when used."},
    { question: "What is the carbon intensity of renewable diesel?", answer: "It varies based on such factors as which feedstock is used (such as rendered animal fat, vegetable oils or used cooking oil), the land impact of the feedstock production, the energy and process used to convert that material into fuel, and the emissions when the fuel is used in vehicles." },
    { question: "What are the benefits of green ammonia?", answer: "Green ammonia is produced using renewable energy sources and emits no carbon dioxide during its production. It serves as a clean and versatile energy carrier, with applications in agriculture, energy storage, and transportation. Its benefits include reduced greenhouse gas emissions, energy storage capacity, and a sustainable alternative to fossil fuels, contributing to a more environmentally friendly and sustainable energy future." }
  ],
  French: [
    { question: "Quels matériaux peuvent être utilisés pour produire du diesel renouvelable ?", answer: "Le diesel renouvelable est produit à partir de matériaux tels que les graisses animales rendues, les huiles végétales, l'huile de maïs des distillateurs, l'huile de canola et les huiles de cuisson usagées." },
    { question: "Le diesel renouvelable a-t-il d'autres noms ?", answer: "Oui, le diesel renouvelable est également connu sous le nom d'huile végétale hydrotraitée (HVO) ou de diesel vert." },
    { question: "Le diesel renouvelable est-il le même que le biodiesel ?", answer: "Non, le diesel renouvelable n'est pas le même que le biodiesel. Bien que les deux soient fabriqués à partir d'huiles végétales ou de graisses animales - et non de pétrole - ils sont traités différemment, ont des caractéristiques de performance différentes, et seul le diesel renouvelable est « prêt pour les moteurs » et peut remplacer complètement le diesel pétrolier sans modifications du moteur. Le biodiesel doit être mélangé avec d'autres combustibles. La plupart des moteurs des fabricants sont calibrés pour fonctionner avec un mélange de carburant ne dépassant pas 20 % de biodiesel." },
    { question: "Le diesel renouvelable brûle-t-il plus proprement que le diesel pétrolier ou le biodiesel ?", answer: "À volume égal, la combustion du diesel renouvelable produit moins de particules, d'oxydes d'azote et d'hydrocarbures aromatiques que les carburants pétroliers. Le diesel renouvelable a également un indice de cétane plus élevé que le diesel à base de pétrole ou le biodiesel. L'indice de cétane est similaire à l'indice d'octane de l'essence ordinaire, en ce sens qu'un indice de cétane plus élevé empêche la combustion prématurée du carburant qui peut provoquer des coups de piston. Cet indice de cétane plus élevé réduit également les émissions du moteur." },
    { question: "L'huile de maïs des distillateurs (DCO) est-elle liée à l'éthanol ? Le diesel renouvelable est-il le même que l'éthanol ?", answer: "L'huile de maïs des distillateurs est un sous-produit du processus de fabrication d'éthanol à partir de maïs. Elle a été utilisée dans l'alimentation animale. Le diesel renouvelable n'est pas le même que l'éthanol, mais le diesel renouvelable peut être produit en utilisant ce sous-produit de l'éthanol." },
    { question: "Pourquoi le diesel renouvelable est-il soudainement en forte demande ?", answer: "Les systèmes de crédit fédéraux et étatiques exigent que les importateurs, raffineurs et grossistes en carburant incluent une proportion croissante de carburants renouvelables dans les produits qu'ils vendent, afin de se conformer aux réglementations. Le diesel renouvelable génère des crédits nécessaires dans les programmes d'État et des numéros d'identification renouvelables (RIN) dans le système fédéral. De plus, le diesel renouvelable peut être utilisé dans les systèmes de moteurs conventionnels comme un remplacement complet ou en combinaison avec le diesel pétrolier sans nécessiter de modifications des moteurs conventionnels. Le diesel renouvelable a également un indice de cétane plus élevé que le diesel à base de pétrole ou le biodiesel. L'indice de cétane est similaire à l'indice d'octane de l'essence ordinaire, en ce sens qu'un indice de cétane plus élevé empêche la combustion prématurée du carburant qui peut provoquer des coups de piston. Cet indice de cétane plus élevé réduit également les émissions du moteur." },
    { question: "Qu'est-ce que le LCFS ?", answer: "LCFS est l'acronyme de la Norme Californienne sur les Carburants à Faible Intensité Carbone, qui est conçue avec l'objectif déclaré de diminuer l'intensité carbone des carburants de transport de Californie et de favoriser la production d'une gamme plus large de carburants alternatifs à faibles émissions de carbone et renouvelables. LCFS est l'un des programmes mis en place, et des programmes similaires sont en cours d'examen par de nombreux États et nations. Le système LCFS stimule la demande de carburants à faibles émissions de carbone par un système de crédits, de déficits et de proportions requises de carburants renouvelables par rapport aux carburants conventionnels. Dans le programme LCFS, les carburants génèrent des crédits LCFS s'ils ont une valeur d'intensité carbone (CI) inférieure à l'objectif du programme de l'année. Les carburants avec des valeurs CI supérieures à l'objectif du programme de l'année génèrent des déficits LCFS. Les producteurs de carburant avec des déficits doivent générer et acquérir suffisamment de crédits pour rester en conformité avec la norme." },
    { question: "Comment fonctionnent les normes provinciales et fédérales sur les carburants ?", answer: "Les programmes de normes sur les carburants ont établi des exigences pour mélanger ou utiliser un certain pourcentage de carburants renouvelables dans les volumes de carburants hydrocarbonés. Ces programmes sont ajustés et supervisés par des programmes fédéraux et provinciaux pour garantir que les carburants renouvelables sont mélangés dans les carburants sur une base annuelle. Ces programmes sont conçus pour réduire les émissions de carbone des carburants de transport." },
    { question: "Quelles sont les réglementations sur les carburants propres au Québec, en Ontario et en Colombie-Britannique ?", answer: "Depuis le 1er janvier 2023, le Québec exige 10 % de contenu en carburants à faibles émissions de carbone dans l'essence en 2023 et augmentera ce pourcentage à 15 % d'ici 2030. La réglementation sur les carburants de transport plus propres en Ontario exige que les fournisseurs de carburant mélangent 10 % de contenu renouvelable dans l'essence de 2020 à 2024. En Colombie-Britannique, l'exigence en matière de carburants renouvelables et à faibles émissions de carbone impose un contenu en éthanol de 5 % dans l'essence et de 4 % dans le diesel." },
    { question: "Qu'est-ce que l'intensité carbone ?", answer: "L'intensité carbone, également connue sous le nom de CI, est une mesure de la quantité de dioxyde de carbone (CO2) ou de dioxyde de carbone équivalent (CO2-e) émise par l'ensemble du cycle de vie d'un carburant par unité d'énergie délivrée. L'intensité carbone varie en fonction des nombreux facteurs du cycle de vie de la production de la matière première, de la conversion en carburant et de l'utilisation. Le diesel renouvelable a une valeur CI plus faible que le diesel pétrolier, mais la valeur CI varie en fonction de la matière première et du processus de transformation du matériau en carburant. Par exemple, le diesel pétrolier a une valeur CI d'environ 100, le diesel renouvelable à partir d'huile de soja a généralement une valeur CI de 53-58, et le diesel renouvelable à partir de graisses animales a généralement une valeur CI de 24-52." },
    { question: "Pourquoi un type de carburant a-t-il une plage CI au lieu d'un nombre fixe ?", answer: "Les carburants ont des plages au lieu de valeurs CI fixes car la valeur CI d'un carburant spécifique variera en fonction des émissions du cycle de vie associées à la production, au traitement et au transport de la matière première, ainsi qu'à son impact sur les émissions lorsqu'il est utilisé."},
    { question: "Quelle est l'intensité carbone du diesel renouvelable ?", answer: "Elle varie en fonction de facteurs tels que le type de matière première utilisé (comme les graisses animales rendues, les huiles végétales ou les huiles de cuisson usagées), l'impact environnemental de la production de la matière première, l'énergie et le processus utilisés pour convertir ce matériau en carburant, et les émissions lorsque le carburant est utilisé dans les véhicules." },
    { question: "Quels sont les avantages de l'ammoniac vert ?", answer: "L'ammoniac vert est produit en utilisant des sources d'énergie renouvelables et n'émet aucun dioxyde de carbone pendant sa production. Il sert de vecteur énergétique propre et polyvalent, avec des applications dans l'agriculture, le stockage d'énergie et le transport. Ses avantages incluent une réduction des émissions de gaz à effet de serre, une capacité de stockage d'énergie, et une alternative durable aux combustibles fossiles, contribuant à un avenir énergétique plus respectueux de l'environnement et durable." }
  ]
};

const FAQ = () => {
  const [openIndexes, setOpenIndexes] = useState([]);
  const { selectedLanguage } = useContext(LanguageContext);

  const faqData = faqData2[selectedLanguage]

  const toggleFAQ = (index) => {
    setOpenIndexes((prev) => 
      prev.includes(index) ? prev.filter(i => i !== index) : [...prev, index]
    );
  };

  return (
    <div>
      <div
        className="flex-col relative h-72 md:h-96 bg-cover bg-center text-center text-white flex items-center justify-center"
        style={{ backgroundImage: `url(${faqBanner})` }}
      >
        <h1 className="text-3xl md:text-7xl font-bold mb-6 md:mb-12 w-4/5 md:w-3/5">
          {selectedLanguage === 'English' ? 'Frequently Asked Questions' : 'Questions Fréquemment Posées'}
        </h1>
        
      </div>
      <div className="container mx-auto py-8 md:py-12 px-4 md:px-12">
        <h2 className="text-2xl md:text-4xl mb-6 text-center font-semibold">
          {selectedLanguage === 'English' ? 'Frequently Asked Questions' : 'Questions Fréquemment Posées'}
        </h2>
        <div className="space-y-4">
          {faqData.map((faq, index) => (
            <div key={index} className="border-b-2 border-gray-400 pb-4">
              <div className="flex justify-between items-center cursor-pointer" onClick={() => toggleFAQ(index)}>
                <h3 className="text-xl md:text-2xl text-gray-800">{faq.question}</h3>
                <span className="text-xl md:text-2xl">{openIndexes.includes(index) ? '-' : '+'}</span>
              </div>
              {openIndexes.includes(index) && (
                <p className="mt-4 md:mt-12 text-gray-700 pb-4 md:pb-12">{faq.answer}</p>
              )}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default FAQ;
